import(/* webpackMode: "eager" */ "/builds/deltagreen/delta-monorepo/node_modules/.pnpm/next@14.2.4_@babel+core@7.25.7_@opentelemetry+api@1.9.0_@playwright+test@1.44.1_react-dom@18.3.1_react@18.3.1/node_modules/next/dist/client/components/app-router.js");
;
import(/* webpackMode: "eager" */ "/builds/deltagreen/delta-monorepo/node_modules/.pnpm/next@14.2.4_@babel+core@7.25.7_@opentelemetry+api@1.9.0_@playwright+test@1.44.1_react-dom@18.3.1_react@18.3.1/node_modules/next/dist/client/components/client-page.js");
;
import(/* webpackMode: "eager" */ "/builds/deltagreen/delta-monorepo/node_modules/.pnpm/next@14.2.4_@babel+core@7.25.7_@opentelemetry+api@1.9.0_@playwright+test@1.44.1_react-dom@18.3.1_react@18.3.1/node_modules/next/dist/client/components/error-boundary.js");
;
import(/* webpackMode: "eager" */ "/builds/deltagreen/delta-monorepo/node_modules/.pnpm/next@14.2.4_@babel+core@7.25.7_@opentelemetry+api@1.9.0_@playwright+test@1.44.1_react-dom@18.3.1_react@18.3.1/node_modules/next/dist/client/components/layout-router.js");
;
import(/* webpackMode: "eager" */ "/builds/deltagreen/delta-monorepo/node_modules/.pnpm/next@14.2.4_@babel+core@7.25.7_@opentelemetry+api@1.9.0_@playwright+test@1.44.1_react-dom@18.3.1_react@18.3.1/node_modules/next/dist/client/components/not-found-boundary.js");
;
import(/* webpackMode: "eager" */ "/builds/deltagreen/delta-monorepo/node_modules/.pnpm/next@14.2.4_@babel+core@7.25.7_@opentelemetry+api@1.9.0_@playwright+test@1.44.1_react-dom@18.3.1_react@18.3.1/node_modules/next/dist/client/components/render-from-template-context.js");
