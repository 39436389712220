import { isNil } from "./typeguards"
import { Primitive } from "./types"

export function getLowestConsecutiveArrayValues<TValue>(
  arr: TValue[],
  count: number,
  accessor: (value: TValue) => number,
): TValue[] {
  if (arr.length <= count) {
    return [...arr]
  }

  let resultSlice: TValue[] = []
  let currentSum = undefined

  for (let i = 0; i < arr.length - count; i++) {
    const slice = [...arr].slice(i, i + count)
    const sum = slice.map((item) => accessor(item)).reduce((acc, cur) => acc + cur, 0)

    if (currentSum === undefined || sum < currentSum) {
      resultSlice = [...slice]
      currentSum = sum
    }
  }

  return resultSlice
}

export function arrayToChunks<TValue>(arr: TValue[], size: number) {
  const myArray = []
  for (let i = 0; i < arr.length; i += size) {
    myArray.push([...arr].slice(i, i + size))
  }

  return myArray
}

export function generateArray(length: number, startNumber = 0) {
  return Array.from(new Array(length).keys()).map((i) => i + startNumber)
}

// @deprecated - use unique
export function distinct<T>(array: T[]) {
  return array.filter((item, index) => index === array.indexOf(item))
}

export function unique<T>(array: T[], accessor?: (item: T) => Primitive) {
  if (accessor) {
    const uniqueValues = new Set<Primitive>()
    const results: T[] = []

    for (const item of array) {
      const value = accessor(item)
      if (!uniqueValues.has(value)) {
        uniqueValues.add(value)
        results.push(item)
      }
    }

    return results
  }

  return [...new Set(array)]
}

export function findWithRest<T>(array: T[], findFn: (item: T) => boolean) {
  let result: T | undefined = undefined
  const rest: T[] = []

  for (const item of array) {
    const found = findFn(item)
    if (found && result === undefined) {
      result = item
      continue
    }

    rest.push(item)
  }

  return [result, rest] as [T | undefined, T[]]
}

export function groupBy<T>(arr: T[], fn: (item: T) => string | number) {
  return arr.reduce<Record<string, T[]>>((prev, curr) => {
    const groupKey = fn(curr)
    const group = prev[groupKey] || []
    group.push(curr)
    return { ...prev, [groupKey]: group }
  }, {})
}

/**
 * Transforms given array to a map with keys being values of array items on given key. If the key value is nullable,
* the items with nil values are skipped.
*
 * @param arr array to transform to a map
 * @param key key with unique values to serve as a map key
 * @param options options for the transformation
 * @returns map with *key* param values as keys and array items as values
 * @throws Error when duplicate *key* values are found
 
 */
export function toMapBy<T extends object, K extends keyof T>(
  arr: T[],
  key: K,
  options?: { throwOnDuplicateKeys?: boolean },
) {
  const { throwOnDuplicateKeys = true } = options ?? {}

  const map = new Map<NonNullable<T[K]>, T>()
  for (const item of arr) {
    const value = item[key]
    if (isNil(value)) {
      continue
    }

    // hack to fix type coertion, which apparently does not work on generic types
    const nonNullableValue = value as NonNullable<typeof value>

    if (map.has(nonNullableValue) && throwOnDuplicateKeys) {
      throw new Error(`Duplicites found for key ${String(key)}. Failed to create map from array.`)
    }

    map.set(nonNullableValue, item)
  }

  return map
}

export function shuffleArray<T>(arr: T[]): T[] {
  const shuffledArray = [...arr]
  for (let i = shuffledArray.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1))
    const temp = shuffledArray[i]
    shuffledArray[i] = shuffledArray[j] as T
    shuffledArray[j] = temp as T
  }

  return shuffledArray
}

export function arrayize<T>(arrayOrSingleVal: T | T[]): T[] {
  if (Array.isArray(arrayOrSingleVal)) {
    return arrayOrSingleVal
  }
  return [arrayOrSingleVal]
}

export function segmentArrayByKey<TItem extends object, Key extends keyof TItem>(
  arr: TItem[],
  keyOrKeyAccessorFn: Key | ((item: TItem) => TItem[Key]),
): Map<TItem[Key], TItem[]> {
  const map = new Map<TItem[Key], TItem[]>()

  for (const item of arr) {
    const value = typeof keyOrKeyAccessorFn === "function" ? keyOrKeyAccessorFn(item) : item[keyOrKeyAccessorFn]

    const items = map.get(value)
    if (items) {
      map.set(value, [...items, item])
      continue
    }

    map.set(value, [item])
  }

  return map
}
